<script setup>
import { BButton, BForm, BFormGroup, BFormInput, BFormSelect, BModal } from 'bootstrap-vue-next';
import { ref } from 'vue';

import { BONUS_POINT_ACTIVITY_TYPES } from '~/common/Constants';

const blankForm = {
  reason: '',
  points: null,
  activity_type: '',
};
const form = ref({ ...blankForm });
const props = defineProps({
  userId: {
    type: Number,
    required: true,
  },
});
const model = defineModel({ type: Boolean });
const emit = defineEmits(['pointsAdded']);

const activityTypeOptions = Object.entries(BONUS_POINT_ACTIVITY_TYPES).map(
  ([value, text]) => ({ value, text }),
);

const addPoints = async (event) => {
  event.preventDefault();

  fetch(`/api/v1/users/${props.userId}/add_points`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form.value),
  }).then(() => {
    emit('pointsAdded', form.value);
    form.value = { ...blankForm };
    model.value = false;
  });
};
</script>

<template>
  <BModal
    v-model="model"
    title="Add points"
    no-footer
  >
    <BForm @submit="addPoints">
      <BFormGroup
        label="Reason:"
        class="mb-3"
      >
        <BFormInput
          v-model="form.reason"
          required
        />
      </BFormGroup>
      <BFormGroup
        label="Points:"
        class="mb-3"
      >
        <BFormInput
          v-model="form.points"
          type="number"
          required
        />
      </BFormGroup>
      <BFormGroup
        label="Activity Type:"
        class="mb-3"
      >
        <BFormSelect
          v-model="form.activity_type"
          :options="activityTypeOptions"
          required
        />
      </BFormGroup>
      <BButton
        type="submit"
        class="float-end"
        variant="primary"
      >
        Add points
      </BButton>
    </BForm>
  </BModal>
</template>
