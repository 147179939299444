<script setup>
import { BButton, BLink, BOverlay, BPagination, BTable } from 'bootstrap-vue-next';
import { inject, ref } from 'vue';

import IssueRewardModal from '~/common/components/IssueRewardModal.vue';
import UserWithGitLabProfileLink from '~/common/components/UserWithGitLabProfileLink.vue';
import { REWARD_REASONS } from '~/common/Constants';
import { dateFormatter } from '~/common/FieldFormats';
import { buildUrl } from '~/common/UrlUtils';

import ActionCard from './home-page/ActionCard.vue';

const vueData = inject('vueData');
const admin = vueData.userAdmin;

const rewards = ref([]);
const filterData = ref({
  currentPage: 1,
});
const pageData = ref({
  perPage: 50,
  totalRows: 0,
});
const busy = ref(true);
const showIssueRewardModal = ref(false);

const giftCodeField = admin
  ? [{
      key: 'issue_web_url',
      label: 'Reward issue',
    }]
  : [];
const fields = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'reason',
    label: 'Reason',
    formatter: value => REWARD_REASONS[value],
  },
  ...giftCodeField,
  {
    key: 'credits',
    label: 'Credits',
  },
  {
    key: 'awarded_by',
    label: 'Issued by',
  },
  {
    key: 'created_at',
    label: 'Issued date',
    formatter: dateFormatter,
  },
];

const fetchData = async (page) => {
  busy.value = true;
  const url = buildUrl('/api/v1/rewards', { query: { page } });
  const response = await fetch(url);
  const { records, metadata } = await response.json();
  rewards.value = records;
  pageData.value = {
    perPage: metadata.per_page,
    totalRows: metadata.total_count,
  };
  busy.value = false;
};

fetchData(1);
</script>

<template>
  <BOverlay
    :show="busy"
    no-wrap
    fixed
  >
    <BButton
      v-if="admin"
      variant="outline-primary"
      data-test-id="issue-rewards-button"
      @click="showIssueRewardModal = true"
    >
      Issue rewards
    </BButton>
    <ActionCard
      title="Reward store"
      action-link="https://rewards.gitlab.com/"
      action-name="Browse rewards"
      class="my-3"
    >
      Earn credits by contributing, levelling up, and supporting the community.
      Redeem your credits for rewards you'll love.
    </ActionCard>
    <BTable
      :fields="fields"
      :items="rewards"
      responsive="md"
      show-empty
    >
      <template #cell(username)="{ value }">
        <UserWithGitLabProfileLink
          :username="value"
          :filter-data="filterData"
        />
      </template>
      <template #cell(issue_web_url)="{ value }">
        <BLink
          is-unsafe-link
          :href="value"
          target="_blank"
        >
          #{{ value.split('/').pop() }}
        </BLink>
      </template>
      <template #cell(awarded_by)="cell">
        <BLink
          is-unsafe-link
          :href="cell.item.awarded_by_web_url"
          target="_blank"
        >
          {{ cell.value }}
        </BLink>
      </template>
    </BTable>
    <BPagination
      v-model="filterData.currentPage"
      :total-rows="pageData.totalRows"
      :per-page="pageData.perPage"
      align="center"
      first-number
      last-number
      @update:model-value="fetchData($event)"
    />
  </BOverlay>

  <IssueRewardModal
    v-if="admin"
    v-model="showIssueRewardModal"
  />
</template>
