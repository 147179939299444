<script setup>
import { BButton, BForm, BFormCheckbox, BFormGroup, BLink } from 'bootstrap-vue-next';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const busy = ref(true);
const user = ref({});
const dirty = ref(false);
let originalCommunityMemberOverrideValue;
const fetchData = async () => {
  const { userId } = route.params;
  const response = await fetch(`/api/v1/users/${userId}/edit`);
  user.value = await response.json();
  originalCommunityMemberOverrideValue = user.value.community_member_override;
  document.title = `Edit ${user.value.username}`;
  busy.value = false;
};

const save = (event) => {
  event.preventDefault();
  fetch(`/api/v1/users/${user.value.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      community_member_override: user.value.community_member_override,
    }),
  });
};

const update = () => {
  dirty.value = originalCommunityMemberOverrideValue != user.value.community_member_override;
};

const clear = () => {
  user.value.community_member_override = null;
  dirty.value = originalCommunityMemberOverrideValue != user.value.community_member_override;
};

fetchData();
</script>

<template>
  <BForm @submit="save">
    <h1>{{ user.username }}</h1>
    <BFormGroup label="Community member (calculated)">
      <BFormCheckbox
        v-model="user.community_member"
        disabled
      />
    </BFormGroup>
    <BFormGroup label="Community member (override)">
      <div class="d-flex">
        <BFormCheckbox
          v-model="user.community_member_override"
          :indeterminate="user?.community_member_override == null"
          @update:model-value="update"
        />
        <BLink
          v-if="user?.community_member_override != null"
          type="button"
          variant="outline-secondary"
          class="ms-2"
          @click="clear"
        >
          Clear
        </BLink>
      </div>
    </BFormGroup>
    <BButton
      :disabled="!dirty"
      type="submit"
      variant="primary"
      class="mt-3"
    >
      Save
    </BButton>
  </BForm>
</template>
