<script setup>
import { BAccordion, BAccordionItem, BPagination } from 'bootstrap-vue-next';
import { ref, computed } from 'vue';

import TableWithBusyState from '~/common/components/TableWithBusyState.vue';

const props = defineProps({
  fields: {
    type: Array,
    default: () => [],
  },
  items: {
    type: Array,
    default: undefined,
  },
  busy: Boolean,
  title: {
    type: String,
    required: true,
  },
  sortKey: {
    type: String,
    required: true,
  },
});

const perPage = ref(10);
const currentPage = ref(1);
const rows = computed(() => props.items?.length);

</script>
<template>
  <BAccordion class="my-2">
    <BAccordionItem visible>
      <template #title>
        <h2 class="d-flex align-items-center justify-content-between w-100">
          <span>{{ title }} ({{ items?.length }})</span>
          <slot name="headerButtons" />
        </h2>
      </template>
      <TableWithBusyState
        :fields="fields"
        :items="items"
        :busy="busy"
        :sort-by="[{key: props.sortKey, order: 'desc'}]"
        :per-page="perPage"
        :current-page="currentPage"
        show-empty
      >
        <template
          v-for="(_, name) in $slots"
          :key="name"
          #[name]="slotData"
        >
          <slot
            :name="name"
            v-bind="slotData"
          />
        </template>
      </TableWithBusyState>
      <BPagination
        v-if="rows && rows > perPage"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        align="center"
        first-number
        last-number
      />
    </BAccordionItem>
  </BAccordion>
</template>
