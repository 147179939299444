<script setup>
import { BCard, BProgress, BProgressBar } from 'bootstrap-vue-next';
import { inject } from 'vue';

import { LEVEL_POINTS } from '~/common/Constants';

import PointsDocumentationTooltip from './PointsDocumentationTooltip.vue';

const props = defineProps({
  minimized: {
    type: Boolean,
    default: false,
  },
  contributorData: {
    type: Object,
    default: () => null,
  },
});

const vueData = inject('vueData');

let contributorLevel;
let contributorPoints;
if (props.contributorData != null) {
  ({ contributorLevel, contributorPoints } = props.contributorData);
} else {
  ({ contributorLevel, contributorPoints } = vueData);
}

const levelStart = LEVEL_POINTS[contributorLevel];
const levelEnd = contributorLevel === 4 ? LEVEL_POINTS[4] : LEVEL_POINTS[contributorLevel + 1];
let total = levelEnd - levelStart;
let progress = contributorPoints - levelStart;

if (progress < 0) {
  total = levelEnd;
  progress = contributorLevel === 4 ? levelEnd : contributorPoints;
}
</script>

<template>
  <BProgress
    v-if="minimized"
    :max="total"
  >
    <BProgressBar
      :value="progress"
      class="text-center level-up"
    />
  </BProgress>
  <BCard
    v-else
    class="overflow-hidden my-2"
    title="Unlock the next achievement!"
  >
    <div
      class="d-flex"
      data-test-id="progress-bar-subtitle"
    >
      <span v-if="contributorLevel < 4">
        <b>Level {{ contributorLevel + 1 }}</b> in {{ Math.ceil(total - progress).toLocaleString() }} points
      </span>
      <span v-else-if="contributorData == null">
        You're a top contributor! {{ contributorPoints.toLocaleString() }} points. The next level-up is coming soon!
      </span>
      <span v-else>
        Top contributor! {{ contributorPoints.toLocaleString() }} points
      </span>
      <PointsDocumentationTooltip />
    </div>
    <div class="py-2">
      <BProgress
        :max="total"
        height="2rem"
      >
        <BProgressBar
          :value="progress"
          class="level-up"
        />
      </BProgress>
    </div>
    <div>
      <a
        href="https://gitlab.com/groups/gitlab-org/-/issues/?sort=created_asc&state=opened&label_name%5B%5D=quick%20win"
        target="_blank"
      >
        Explore issues to contribute
      </a>
    </div>
  </BCard>
</template>
