<script setup>
import { BSpinner } from 'bootstrap-vue-next';
import { marked } from 'marked';
import { gfmHeadingId } from 'marked-gfm-heading-id';
import { ref } from 'vue';

import { DEFAULT_TITLE } from '~/common/Constants';

import renderer from './documentation-page/markedRenderer';
import NotFoundPage from './NotFoundPage.vue';

const props = defineProps({
  page: {
    type: String,
    required: true,
  },
});

const content = ref('');
const renderedContent = ref('');
const notFound = ref(false);

marked.use({ renderer });
marked.use(gfmHeadingId());

const setPageTitle = () => {
  const title = content.value.trimStart().split('\n')[0];
  if (title.startsWith('# ')) {
    document.title = `${title.substring(2)} - ${DEFAULT_TITLE}`;
  }
};

const scrollToAnchor = () => {
  const anchor = location.hash;
  if (anchor) {
    location.hash = '';
    setTimeout(() => {
      location.hash = anchor;
    }, 0);
  }
};

const loadContent = async () => {
  const response = await fetch(`/docs/${props.page}.md`);
  if (response.headers.get('content-type') === 'text/plain') {
    content.value = await response.text();
    renderedContent.value = marked(content.value);
    notFound.value = false;

    setPageTitle();
    scrollToAnchor();
  } else {
    notFound.value = true;
  }
};

loadContent();
</script>

<template>
  <div class="documentation-page">
    <NotFoundPage v-if="notFound" />
    <div
      v-else-if="content"
      class="markdown-content"
      v-html="renderedContent"
    />
    <div
      v-else
      class="loading"
    >
      <BSpinner />
      <div>Loading documentation...</div>
    </div>
  </div>
</template>
