<script setup>
import { computed } from 'vue';

import contributorLevelOneBadge from '@images/contributor-level-1.png';
import contributorLevelTwoBadge from '@images/contributor-level-2.png';
import contributorLevelThreeBadge from '@images/contributor-level-3.png';
import contributorLevelFourBadge from '@images/contributor-level-4.png';

const props = defineProps({
  badgeSize: {
    type: Number,
    default: 40,
  },
  contributorLevel: {
    type: Number,
    default: 0,
  },
});

const contributorLevelBadge = {
  1: contributorLevelOneBadge,
  2: contributorLevelTwoBadge,
  3: contributorLevelThreeBadge,
  4: contributorLevelFourBadge,
};

const contributorLevelBadgeTitle = computed(() => `Contributor level ${props.contributorLevel} badge`);

const validContributorLevel = computed(() => Object.keys(contributorLevelBadge).includes(props.contributorLevel.toString()));
</script>

<template>
  <img
    v-if="validContributorLevel"
    :src="contributorLevelBadge[props.contributorLevel]"
    :height="props.badgeSize"
    :width="props.badgeSize"
    :alt="contributorLevelBadgeTitle"
    :title="contributorLevelBadgeTitle"
  >
</template>
