<script setup>
import { BLink } from 'bootstrap-vue-next';
import { vBTooltip } from 'bootstrap-vue-next';

import FaExternalLink from '~icons/fa/external-link';

const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  filterData: {
    type: Object,
    default: () => ({}),
  },
});
</script>

<template>
  <BLink
    :to="{
      name: 'user',
      params: { userHandle: props.username },
      query: { fromDate: props.filterData.fromDate, toDate: props.filterData.toDate }
    }"
  >
    {{ username }}
  </BLink>
  <a
    v-b-tooltip="'View on GitLab.com'"
    :href="`https://gitlab.com/${props.username}`"
    target="_blank"
    class="ms-2 text-secondary"
  >
    <FaExternalLink class="external-link-icon" />
  </a>
</template>
