<script setup>
import { BButton, BForm, BFormGroup, BFormInput, BFormSelect, BModal, BFormTextarea, BFormRadioGroup } from 'bootstrap-vue-next';
import { ref } from 'vue';

const blankForm = {
  name: '',
  date: null,
  link: null,
  role: null,
  virtual: null,
  size: null,
  note: '',
};
const form = ref({ ...blankForm });
const busy = ref(false);
const model = defineModel({ type: Boolean });
const emit = defineEmits(['eventAdded']);
const ROLE_OPTIONS = [
  { text: 'Speaker', value: 'speaker' },
  { text: 'Organizer', value: 'organizer' },
];
const SIZE_OPTIONS = [
  { text: 'Small (< 20)', value: 'small' },
  { text: 'Medium (20 - 100)', value: 'medium' },
  { text: 'Large (100+)', value: 'large' },
];
const TYPE_OPTIONS = [
  { text: 'Virtual', value: true },
  { text: 'In-person', value: false },
];

const addPoints = async (event) => {
  event.preventDefault();
  busy.value = true;
  const response = await fetch('/api/v1/events', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(form.value),
  });
  const issue = await response.json();
  window.open(issue.web_url, '_blank');
  busy.value = false;
  emit('eventAdded', form.value);
  model.value = false;
};

const resetForm = () => {
  form.value = { ...blankForm };
};
</script>

<template>
  <BModal
    v-model="model"
    title="Add event"
    no-footer
    @hidden="resetForm"
  >
    <BForm
      @submit="addPoints"
    >
      <BFormGroup
        label="Name"
        class="mb-3"
      >
        <BFormInput
          v-model="form.name"
          required
          data-test-id="event-modal-input-name"
        />
      </BFormGroup>
      <BFormGroup
        label="Date"
        class="mb-3"
      >
        <BFormInput
          v-model="form.date"
          type="date"
          required
          data-test-id="event-modal-input-date"
        />
      </BFormGroup>
      <BFormGroup
        label="Link"
        class="mb-3"
      >
        <BFormInput
          v-model="form.link"
          data-test-id="event-modal-input-link"
        />
      </BFormGroup>
      <BFormGroup
        label="Role"
        class="mb-3"
      >
        <BFormRadioGroup
          v-model="form.role"
          :options="ROLE_OPTIONS"
          required
          stacked
          data-test-id="event-modal-radio-role"
        />
      </BFormGroup>
      <BFormGroup
        label="Type"
        class="mb-3"
      >
        <BFormRadioGroup
          v-model="form.virtual"
          :options="TYPE_OPTIONS"
          required
          stacked
          data-test-id="event-modal-radio-virtual"
        />
      </BFormGroup>
      <BFormGroup
        label="Size"
        class="mb-3"
        reqiured
      >
        <BFormSelect
          v-model="form.size"
          :options="SIZE_OPTIONS"
          required
          data-test-id="event-modal-select-size"
        />
      </BFormGroup>
      <BFormGroup
        label="Note"
        class="mb-3"
      >
        <BFormTextarea
          v-model="form.note"
          type="text"
          data-test-id="event-modal-input-note"
        />
      </BFormGroup>
      <BButton
        type="submit"
        class="float-end"
        variant="primary"
        :loading="busy"
      >
        Add event
      </BButton>
    </BForm>
  </BModal>
</template>
